import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { ReloadiframeService } from "src/app/servicios/reloadiframe.service";
import { DestinoService } from "../../servicios/destino.service";
import { Destino } from "src/app/entidades/destino";
import { MatTableDataSource } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { login } from "src/app/entidades/login";
import { recolecciondom } from "../../servicios/recoleccion-dom.service";


@Component({
  selector: "app-cobertura",
  templateUrl: "./cobertura.component.html",
  styleUrls: ["./cobertura.component.css"],
})
export class CoberturaComponent implements OnInit {
  searchText: string;
  url: any;
  origenSeleccionado: Destino;
  listubicacion: any[];

  lati: number;
  long: number;
  zoomdefault: number;
  maptypeId: string;
  activeIndex: number = 0;
  activeDiv: number = 0;
  visitedIndex: number = 0;
  clicked: number =0;
  //atributos del arreglo
  id = 1;
  lat: number;
  lng: number;
  name: string;
  address: string;
  schedule: string;
  feature: string;
  phone: string;

  LatLngBounds: any[];

  constructor(
    private sanitizer: DomSanitizer,
    private destinoService: DestinoService,
    private reloadIframeS: ReloadiframeService,
    private recolecciondom: recolecciondom,
    private http: HttpClient
  ) {
    //Sonora como datos iniciales del mapa
    this.lati = 29.33333;
    this.long = -110.66667;
    this.zoomdefault = 5.3;
    this.maptypeId = "roadmap";

  }

  ngOnInit() {
    this.destinoService.origenSeleccionado.subscribe(
      (data) => (this.origenSeleccionado = data)
    );

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.URLTools +
        "/cobertura?lang=" +
        environment.language +
        "&v=" +
        environment.version +
        "&tipo=PQT&Cd=" +
        this.origenSeleccionado.CodOrg
    );

    this.reloadIframeS.subject$.subscribe((d) => {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        environment.URLTools +
          "/cobertura?lang=" +
          environment.language +
          "&v=" +
          environment.version +
          "&tipo=PQT&Cd=" +
          this.origenSeleccionado.CodOrg
      );
    });

  }

  getlanguage() {
    return environment.language;
  }

  // arreglo con los datos del mapa

  ubicacionesData =[
    {
        "id": 29,
        "lat": 32.63589711,
        "lng": -115.4683598,
        "zoom": 19,
        "name": "Mexicali Centro Cívico - Mexicali, B.C.",
        "address": "Av. Francisco Sarabia #15, Col. Zacatecas, C.P. 21070 Mexicali, B.C.",
        "schedule": "Lunes a Sábado de 8:00 a 18:00 hrs - Domingo Cerrado",
        "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, cafetería, pantalla, aire acondicionado.",
        "phone": "01 (686) 555 5818"
    },
    {
        "id": 5,
        "lat": 32.50292018,
        "lng": -116.9502167,
        "zoom": 19,
        "name": "Módulo Insurgente - Tijuana, B.C.",
        "address": "Av. Insurgentes #16779 entre Blvd Matamoros e Insurgentes, Col. Zona Rio tercera etapa C.P. 22110",
        "schedule": "Lunes a Sábado de 8:00 a 20:00 hrs - Domingo cerrado",
        "feature": "Terminal con taquilla, baños hombre - mujer, sala VIP, Cafetería, pantallas, WiFi, aire acondicionado.",
        "phone": "01 (664) 207 1066"
    },
    {
        "id": 9,
        "lat": 20.62857205,
        "lng": -103.3310147,
        "zoom": 19,
        "name": "Centro Operativo - Guadalajara Jalisco",
        "address": "Esmeril #1985, Lázaro Cárdenas, C.P. 44490 Guadalajara, Jal.",
        "schedule": "Lunes a Viernes 8:00 a 20:00 hrs - Sábado 8:00 a 15:00 hrs - Domingo cerrado",
        "feature": "Centro Operativo",
        "phone": "01 (333) 860 2282"
    },
    {
        "id": 59,
        "lat": 20.64225955,
        "lng": -103.4204034,
        "zoom": 19,
        "name": "Modulo Patria, Zapopan Jalisco",
        "address": "Av. Patria #2690 int 1, entre Volcan Ajusco y Volcan Popocatépetl Col. Colli Urbano C.P. 45070 Zapopan Jalisco",
        "schedule": "Lunes a Viernes 10:00 a 18:00 hrs - Sábado 9:00 a 13:00 hrs - Domingo cerrado",
        "feature": "Sucursal de paqueteria",
        "phone": "01 (333) 630 8607"
    },
    {
        "id": 21,
        "lat": 21.473172,
        "lng": -104.853518,
        "zoom": 19,
        "name": "Módulo Tepic - Tepic, Nayarit",
        "address": "Blvrd Luis Donaldo Colosio  #30, Microindustria, C.P. 63173 Tepic, Nay.",
        "schedule": "Lunes a Sabado 9:00 a 17:00 hrs - Domingo Cerrado",
        "feature": "Terminal con taquilla, sala de espera, WiFi, television, cafeteria, aire acondicionado, bancas afuera",
        "phone": "01 (311) 129 2010"
    },
    {
        "id": 27,
        "lat": 22.4903798,
        "lng": -105.3828353,
        "zoom": 19,
        "name": "Módulo Acaponeta - Acaponeta, Nayarit",
        "address": "Prolongación Morelos s/n, Crucero de Acaponeta, C.P. 63400",
        "schedule": "Lunes a Domingo 6:30 a 20:30 hrs",
        "feature": "Terminal con taquilla, sala de espera, baños hombre- mujer, WiFi, aire acondicionado, bancas",
        "phone": "01 (325) 251 1388"
    },
    // {
    //     "id": 32,
    //     "lat": 22.39499704,
    //     "lng": -105.4576295,
    //     "zoom": 19,
    //     "name": "Módulo Tecuala, Nayarit",
    //     "address": "Zaragoza # 71 PTE entre Guadalajara y Guanajuato Col. Centro, Tecuala, Nayarit C.P. 63440",
    //     "schedule": "Lunes a Domingo 6:30 a 20:00 hrs",
    //     "feature": "Modulo de paqueteria y venta de boletos",
    //     "phone": "01 (389) 253 2197"
    // },
    {
        "id": 40,
        "lat": 24.7985245,
        "lng": -107.4328908,
        "zoom": 19,
        "name": "Modulo Central Culiacán, Sinaloa",
        "address": "Alfonso G. Calderón Col Nuevo Milenium local 7 Central de Autobuses",
        "schedule": "Lunes a Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs",
        "feature": "Modulo de paqueteria y venta de boletos",
        "phone": "01 (667) 714 1558"
    },
    {
        "id": 13,
        "lat": 24.77827369,
        "lng": -107.4360789,
        "zoom": 19,
        "name": "Centro Operativo Canacintra Culiacán, Sinaloa",
        "address": "Industrias del Valle #2835 Parque Industrial Canacintra II, Culiacán Sinaloa, México. C.P. 80150",
        "schedule": "Lunes a Viernes 7:00 a 19:00 hrs - Sabados 7:00 a 15:00 Domingo cerrado",
        "feature": "Centro Operativo",
        "phone": "(667)760 0504"
    },
    {
        "id": 19,
        "lat": 25.57442589,
        "lng": -108.4661241,
        "zoom": 19,
        "name": "Módulo Guasave, Sinaloa",
        "address": "Blvd. Central 105, Centro, 81200 Guasave, Sin.",
        "schedule": "Lunes a Sábado 7:00 a 20:00 hrs - Domingo cerrado",
        "feature": "Terminal con taquilla, baños hombre - mujer, sala de espera, WiFi, aire acondicionado, pantallas, cafetería.",
        "phone": "01 (687) 721 1236"
    },
    {
        "id": 18,
        "lat": 25.81973,
        "lng": -108.98047,
        "zoom": 19,
        "name": "Módulo Los Mochis, Sinaloa",
        "address": "Blvd. Rosales # 2465 entre Zacarias Ochoa y Blvd. Poseidon, Col. Miguel Hidalgo C.P. 81210",
        "schedule": "Lunes a Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs",
        "feature": "Terminal con taquilla, baños hombre-mujer, sala de espera, WiFi, cafetería, pantallas.",
        "phone": "01 (668) 817 5551"
    },
    {
        "id": 20,
        "lat": 25.46050106,
        "lng": -108.0775403,
        "zoom": 19,
        "name": "Módulo Guamuchil, Sinaloa",
        "address": "Nicolás bravo y Salvador Alvarado col&nbsp;Juárez, C.P. 81400",
        "schedule": "Lunes a Sábado 7:00 a 20:00 hrs - Domingo cerrado",
        "feature": "Terminal con taquilla, un baño, sala de espera, WiFi, pantallas, cafetería, aire acondicionado.",
        "phone": "01 (673) 117 0125"
    },
    {
        "id": 23,
        "lat": 23.24324765,
        "lng": -106.4216769,
        "zoom": 19,
        "name": "Módulo Mazatlán, Sinaloa",
        "address": "Carretera Internacional al norte Km 1201 Nte, Prados del Sol, 82129 Mazatlán, Sin.",
        "schedule": "Lunes a Viernes 7:00 a 20:00 hrs - Sábado 7:00 a 18:00 hrs - Domingo 8:00 a 15:00 hrs",
        "feature": "Terminal con taquilla, sala de espera, baños hombre-mujer, WiFi, cafetería, pantallas, aire acondicionado",
        "phone": "01 (669) 968 0351"
    },
    // {
    //     "id": 28,
    //     "lat": 22.83481394,
    //     "lng": -105.7744192,
    //     "zoom": 19,
    //     "name": "Módulo Escuinapa, Sinaloa",
    //     "address": "Calle Hidalgo #44B Col. Centro C.P. 82400",
    //     "schedule": "Lunes a Domingo 6:00 a 20:00 hrs",
    //     "feature": "Terminal con taquilla, sala de espera pequeña, baños hombre- mujer, WiFi",
    //     "phone": "01 (695) 953 0288"
    // },
    {
        "id": 30,
        "lat": 22.99309273,
        "lng": -105.8618941,
        "zoom": 19,
        "name": "Módulo El Rosario, Sinaloa",
        "address": "Avenida Rotarismo s/n salida a Agua Verde, Col. Marcelo Loya, El Rosario, Sinaloa, C.P. 82808",
        "schedule": "Lunes a Domingo 6:00 a 20:00 hrs",
        "feature": "Terminal con taquilla, sala de espera, aire acondicionado.",
        "phone": "01 (694) 952 0138"
    },
    {
        "id": 17,
        "lat": 29.09979542,
        "lng": -110.994087,
        "zoom": 19,
        "name": "Módulo Plaza Girasol - Hermosillo, Sonora",
        "address": "Blvd. Solidaridad No. 56 Local 17, Col. El Sahuaro, C.P. 83178",
        "schedule": "Lunes a Viernes 9:00 a 19:00 hrs - Sábado 9:00 a 14:00 hrs - Domingo cerrado",
        "feature": "Modulo de paqueteria y venta de boletos",
        "phone": "01 (662) 216 5600"
    },
    {
        "id": 10,
        "lat": 32.482171,
        "lng": -114.776032,
        "zoom": 19,
        "name": "Módulo Centro - S.L.R.C., Sonora",
        "address": "Alvaro Obregón S/N, entre 5ta y 6ta, Col. Centro C.P. 83449",
        "schedule": "Lunes a Sábado de 8:00 a 20:00 hrs - Domingo de 8:00 a 15:00 hrs",
        "feature": "Terminal con taquilla, baño hombre- mujer, sala de espera, pantalla, aire acondicionado.",
        "phone": "01 (653) 534 5832"
    },
    {
        "id": 31,
        "lat": 31.8675214,
        "lng": -112.8454559,
        "zoom": 19,
        "name": "Módulo Sonoyta, Sonora",
        "address": "Blvd. Benemérito de las Américas y Blvd. Eusebio Kino No. 104, Local F, Col. Centro, C.P. 83570",
        "schedule": "Lunes a Domingo 8:00 a 21:00 hrs",
        "feature": "Terminal con taquilla, sala de espera, WiFi, aire acondicionado.",
        "phone": "01 (651) 512 0868"
    },
    {
        "id": 6,
        "lat": 30.71734039,
        "lng": -112.1430966,
        "zoom": 19,
        "name": "Módulo Caborca, Sonora",
        "address": "Carretera Internacional s/n entre Calle 8 y 9, C.P. 83600",
        "schedule": "Lunes a Sabado 9:00 a 17:00  -  Domingo cerrado",
        "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, pantallas, aire acondicionado.",
        "phone": "01 (637) 372 3694"
    },
    {
        "id": 24,
        "lat": 31.29643492,
        "lng": -110.9373042,
        "zoom": 19,
        "name": "Módulo Nogales, Sonora",
        "address": "Prolongacion Ave. Alvaro Obregón # 2030, Col. Jardines Kalitea, C.P. 84160",
        "schedule": "Lunes a Sábado 7:00 a 21:00 hrs - Domingo 8:00 a 15:00 hrs",
        "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, cafetería, pantallas, WiFi, barra de conectores, aire acondicionado",
        "phone": "01 (631) 313 9965"
    },
    // {
    //     "id": 33,
    //     "lat": 30.78247477,
    //     "lng": -110.8534278,
    //     "zoom": 19,
    //     "name": "Módulo Imuris, Sonora",
    //     "address": "Luis Donaldo Colosio # 175 Col. Centro, CP 84120",
    //     "schedule": "Lunes a Domingo 6:30 a 21:00 hrs",
    //     "feature": "Terminal con taquilla, sala de espera, aire acondicionado.",
    //     "phone": "01 (632) 688 1698"
    // },
    {
        "id": 22,
        "lat": 30.62746863,
        "lng": -110.9679257,
        "zoom": 19,
        "name": "Módulo Magdalena - Magdalena de Kino, Sonora",
        "address": "Allende #103, Col. Centro, C.P.84160",
        "schedule": "Lunes a Sabado 9:00 a 15:00 hrs - Domingo cerrado",
        "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, pantallas, aire acondicionado.",
        "phone": "01 (632) 322 0613"
    },
    {
        "id": 17,
        "lat": 29.07829148,
        "lng": -110.9306085,
        "zoom": 19,
        "name": "Centro Operativo Hermosillo, Sonora",
        "address": "Blvd. Luis Encinas y Ferrocarril #393 Col. Colosio Bajo C.P 84160",
        "schedule": "Lunes a Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs",
        "feature": "Sucursal de paqueteria",
        "phone": "01 (662) 289 6970"
    },
    {
        "id": 1,
        "lat": 31.31234481,
        "lng": -109.5576627,
        "zoom": 19,
        "name": "Módulo Agua Prieta, Sonora",
        "address": "Avenida 6 y Calle 25 S/N, Col. Ejidal C.P. 84200",
        "schedule": "Lunes a Viernes 7:00 a 21:00 hrs - Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs",
        "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, cafetería, pantalla, aire acondicionado.",
        "phone": "01 (633) 338 3773"
    },
    {
        "id": 26,
        "lat": 30.54886,
        "lng": -111.119146,
        "zoom": 19,
        "name": "Módulo Santa Ana, Sonora",
        "address": "General Anaya y Cuahutemoc s/n, Col. Del Rio, C.P.84600",
        "schedule": "Lunes a Viernes 9:00 a 17:00 hrs - Sábado 9:00 a 13:00 hrs - Domingo cerrado.",
        "feature": "Terminal con taquilla, sala de espera, baños hombre-mujer, pantallas, aire acondicionado.",
        "phone": "01 (641) 324 0124"
    },
    {
        "id": 2,
        "lat": 30.98795107,
        "lng": -110.2864146,
        "zoom": 19,
        "name": "Módulo Cananea, Sonora",
        "address": "Camino Real y Blvd. s/n, Colonia Linda Vista C.P. 84620",
        "schedule": "Lunes a Viernes 8:00 a 14:00hrs y de 16:00 a 18hrs - Sábado 7:00 a 14:00 hrs - Domingo Cerrado",
        "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, pantallas, aire acondicionado.",
        "phone": "01 (645) 332 2500"
    },
    {
        "id": 12,
        "lat": 27.48869805,
        "lng": -109.9281655,
        "zoom": 19,
        "name": "Corporativo Obregon Sufragio - Obregón, Sonora",
        "address": "Sufragio Efectivo #440 Sur entre Zaragoza y Nicolas Bravo, Colonia Centro C.P. 85000",
        "schedule": "Lunes a Viernes 7:30 a 17:00 hrs Sábados y Domingo cerrado",
        "feature": "Corporativo Grupo TUFESA",
        "phone": "01 (644) 410 9444"
    },
    {
        "id": 35,
        "lat": 29.11380828,
        "lng": -110.9729538,
        "zoom": 19,
        "name": "Módulo Monteverde - Hermosillo, Sonora",
        "address": "Monteverde esquina Camelia s/n Col. Luis Encinas CP 85138",
        "schedule": "Lunes a Viernes de 9:00 a 18:00 hrs  - Sabados 9:00 a 13:00  - Domingo cerrado",
        "feature": "Terminal con taquilla, baño hombre - mujer, 2 salas de espera, pantallas, cafeteria, aire acondicionado.",
        "phone": "01 (662) 289 6999"
    },
    {
        "id": 11,
        "lat": 27.481338,
        "lng": -109.945187,
        "zoom": 19,
        "name": "Módulo Obregon, Sonora",
        "address": "California #1019 entre Guadalupe Victoria y Churubusco, Colonia Sochiloa C.P. 85150",
        "schedule": "Lunes a Sabado 7:00 a 20:0 hrs - Domingo 8:00 a 15:00 hrs",
        "feature": "Centro de paqueteria con sala de espera y aire aocndicionado",
        "phone": "01 (644) 416 2668"
    },
    {
        "id": 3,
        "lat": 27.95243361,
        "lng": -110.8123985,
        "zoom": 19,
        "name": "Módulo Empalme, Sonora",
        "address": "Carretera Internacional, Km 2, Sector Oriente Frente al Cuartel C.P.85330",
        "schedule": "Lunes a Sábado 9:00 a 17:00 hrs - Domingo cerrado",
        "feature": "\"Terminal con taquilla, sala de espera, baños hombre - mujer, aire acondicionado, pantallas.\"\"\"",
        "phone": "01 (622) 223 2770"
    },
    {
        "id": 16,
        "lat": 27.92499614,
        "lng": -110.9104175,
        "zoom": 19,
        "name": "Módulo Guaymas, Sonora",
        "address": "Blvd. Calzada Agustín Garcia Lopez y Calle Jaiba # 927, Col. Las Delicias, C.P. 85427",
        "schedule": "Lunes a Sabado 7:00 a 20:00 hrs -  Domingo cerrado",
        "feature": "Terminal con taquilla, sala de espera, baño hombre - mujer, WiFi, cafetería, pantallas, aire acondicionado.",
        "phone": "01 (622) 222 5453"
    },
    {
        "id": 4,
        "lat": 27.07622333,
        "lng": -109.4442635,
        "zoom": 19,
        "name": "Módulo Navojoa, Sonora",
        "address": "Calle Gral. I. Pesqueira #515, Juárez, 85800 Navojoa, Son.",
        "schedule": "Lunes a Sabado 7:00 a 20:00 hrs - Domingo cerrado",
        "feature": "Terminal con taquilla , baños hombre - mujer, sala de espera, cafetería, televisión, WiFi, aire acondicionado.",
        "phone": "01 (642) 421 3410 EXT. 103"
    },
    {
        "id": 37,
        "lat": 26.82971275,
        "lng": -109.6416283,
        "zoom": 19,
        "name": "Módulo Huatabampo, Sonora",
        "address": "16 de Septiembre 534, Centro Comercial, 85900 Huatabampo, Son.",
        "schedule": "Lunes a Sábado 8:00 a 20:00 hrs - Domingo cerrado",
        "feature": "Modulo de paqueteria y venta de boletos",
        "phone": "01 (642) 421 3410"
    },
    {
        "id": 36,
        "lat": 29.09686552,
        "lng": -110.9586878,
        "zoom": 19,
        "name": "Modulo San Benito - Hermosillo, Sonora",
        "address": "Calle Gral. José María Yáñez #143, Modelo, 83190 Hermosillo, Son.",
        "schedule": "Lunes a Viernes 9:00 a 18:00 - Sabado 9:00 a 15:00 - Domingo cerrado",
        "feature": "Modulo de paqueteria y venta de boletos",
        "phone": "01 (662) 436 2443"
    },
    {
      "id": 36,
      "lat": 29.09686552,
      "lng": -110.9586878,
      "zoom": 19,
      "name": "Modulo Central - Hermosillo, Sonora",
      "address": "Boulevard Luis Encinas Jhonson #337, colonia universidad, Hermosillo, Son.",
      "schedule": "Lunes a Sábado de 9:00 a 17:00 - Domingo cerrado",
      "feature": "Modulo de paqueteria y venta de boletos",
      "phone": ""
    }
]


//   ubicacionesData = [
//     {
//         "id": 29,
//         "lat": 32.6358971109484,
//         "lng": -115.468359832854,
//         "zoom": 19,
//         "name": "Mexicali Centro Cívico - Mexicali, B.C.",
//         "address": "Av. Francisco Sarabia #15, Col. Zacatecas, C.P. 21070 Mexicali, B.C.",
//         "schedule": "Lunes a Sábado de 8:00 a 20:00 hrs - Domingo de 7:00 a 15:00 hrs",
//         "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, cafetería, pantalla, aire acondicionado.",
//         "phone": "01 (686) 555 5818"
//     },
//     {
//         "id": 15,
//         "lat": 32.6231612666851,
//         "lng": -115.406588942147,
//         "zoom": 19,
//         "name": "Módulo Mexicali Carranza - Mexicali, B.C.",
//         "address": "Calzada Lazaro Cardenas #3198, int. 13-15, Col. Villa Verde C.P. 21395",
//         "schedule": "Lunes a Sábado de 8:00 a 20:00 hrs Domingo de 7:00 a 15:00 hrs",
//         "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, pantalla, aire acondicionado.",
//         "phone": "01 (686) 555 0371"
//     },
//     {
//         "id": 5,
//         "lat": 32.50292017880501,
//         "lng": -116.95021672057936,
//         "zoom": 19,
//         "name": "Módulo Insurgente - Tijuana, B.C.",
//         "address": "Av. Insurgentes #16779 entre Blvd Matamoros e Insurgentes, Col. Zona Rio tercera etapa C.P. 22110",
//         "schedule": "Lunes a Sábado de 8:00 a 20:00 hrs - Domingo cerrado",
//         "feature": "Terminal con taquilla, baños hombre - mujer, sala VIP, Cafetería, pantallas, WiFi, aire acondicionado.",
//         "phone": "01 (664) 207 1066"
//     },
//     {
//         "id": 9,
//         "lat": 20.628572046403146,
//         "lng": -103.3310146658388,
//         "zoom": 19,
//         "name": "Centro Operativo - Guadalajara Jalisco",
//         "address": "Esmeril #1985, Lázaro Cárdenas, C.P. 44490 Guadalajara, Jal.",
//         "schedule": "Lunes a Viernes 8:00 a 20:00 hrs - Sábado 8:00 a 15:00 hrs - Domingo Cerrado",
//         "feature": "",
//         "phone": "01 (333) 860 2282"
//     },
//     {
//         "id": 21,
//         "lat": 21.473172,
//         "lng": -104.853518,
//         "zoom": 19,
//         "name": "Módulo Tepic - Tepic, Nayarit",
//         "address": "Blvrd Luis Donaldo Colosio  #30, Microindustria, C.P. 63173 Tepic, Nay.",
//         "schedule": "Lunes a Viernes 7:00 a 21:00 hrs - Sábado 7:00 a 15:00 hrs Domingo Cerrado",
//         "feature": "Terminal con taquilla, sala de espera, WiFi, television, cafeteria, aire acondicionado, bancas afuera",
//         "phone": "01 (311) 129 2010"
//     },
//     {
//         "id": 27,
//         "lat": 22.4903798,
//         "lng": -105.3828353,
//         "zoom": 19,
//         "name": "Módulo Acaponeta - Acaponeta, Nayarit",
//         "address": "Prolongación Morelos s/n, Crucero de Acaponeta, C.P. 63400",
//         "schedule": "Lunes a Domingo 6:30 a 20:30 hrs",
//         "feature": "Terminal con taquilla, sala de espera, baños hombre- mujer, WiFi, aire acondicionado, bancas",
//         "phone": "01 (325) 251 1388"
//     },
//     {
//         "id": 32,
//         "lat": 22.394997035759594,
//         "lng": -105.45762946135753,
//         "zoom": 19,
//         "name": "Módulo Tecuala, Nayarit",
//         "address": "Zaragoza # 71 PTE entre Guadalajara y Guanajuato Col. Centro, Tecuala, Nayarit C.P. 63440",
//         "schedule": "Lunes a Domingo 6:30 a 20:00 hrs",
//         "feature": "",
//         "phone": "(389) 253 2197"
//     },
//     {
//         "id": 40,
//         "lat": 24.7985245,
//         "lng": -107.4328908,
//         "zoom": 19,
//         "name": "Modulo Central Culiacán, Sinaloa",
//         "address": "Alfonso G. Calderón Col Nuevo Milenium local 7 Central de Autobuses",
//         "schedule": "Lunes a Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs",
//         "feature": "",
//         "phone": "(667) 714 1558"
//     },
//     {
//         "id": 13,
//         "lat": 24.778273691801246,
//         "lng": -107.43607887329117,
//         "zoom": 19,
//         "name": "Centro Operativo Canacintra Culiacán, Sinaloa",
//         "address": "Industrias del Valle #2835 Parque Industrial Canacintra II, Culiacán Sinaloa, México. C.P. 80150",
//         "schedule": "Lunes a Viernes 7:00 a 19:00 hrs - Sabados 7:00 a 15:00 Domingo Cerrados",
//         "feature": "Centro Operativo",
//         "phone": "01 (667) 761 0339"
//     },
//     {
//         "id": 19,
//         "lat": 25.57442589068559,
//         "lng": -108.46612411697107,
//         "zoom": 19,
//         "name": "Módulo Guasave, Sinaloa",
//         "address": "Blvd. Central 105, Centro, 81200 Guasave, Sin.",
//         "schedule": "Lunes a Sábado 7:00 a 20:00 hrs - Domingo Cerrado",
//         "feature": "Terminal con taquilla, baños hombre - mujer, sala de espera, WiFi, aire acondicionado, pantallas, cafetería.",
//         "phone": "01 (687) 872 4859"
//     },
//     {
//         "id": 18,
//         "lat": 25.81973,
//         "lng": -108.98047,
//         "zoom": 19,
//         "name": "Módulo Los Mochis, Sinaloa",
//         "address": "Blvd. Rosales # 2465 entre Zacarias Ochoa y Blvd. Poseidon, Col. Miguel Hidalgo C.P. 81210",
//         "schedule": "Lunes a Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs",
//         "feature": "Terminal con taquilla, baños hombre-mujer, sala de espera, WiFi, cafetería, pantallas.",
//         "phone": "01 (668) 817 5551"
//     },
//     {
//         "id": 20,
//         "lat": 25.460501057761977,
//         "lng": -108.07754030608305,
//         "zoom": 19,
//         "name": "Módulo Guamuchil, Sinaloa",
//         "address": "Nicolás bravo y Salvador Alvarado col&nbsp;Juárez, C.P. 81400",
//         "schedule": "Lunes a Sábado 7:00 a 20:00 hrs - Domingo Cerrado",
//         "feature": "Terminal con taquilla, un baño, sala de espera, WiFi, pantallas, cafetería, aire acondicionado.",
//         "phone": "01 (673) 117 0125"
//     },
//     {
//         "id": 23,
//         "lat": 23.243247652751386,
//         "lng": -106.421676903119,
//         "zoom": 19,
//         "name": "Módulo Mazatlán, Sinaloa",
//         "address": "Carretera Internacional al norte Km 1201 Nte, Prados del Sol, 82129 Mazatlán, Sin.",
//         "schedule": "Lunes a Viernes 7:00 a 20:00 hrs - Sábado 7:00 a 18:00 hrs - Domingo 8:00 a 15:00 hrs",
//         "feature": "Terminal con taquilla, sala de espera, baños hombre-mujer, WiFi, cafetería, pantallas, aire acondicionado",
//         "phone": "01 (669) 968 0351"
//     },
//     {
//         "id": 28,
//         "lat": 22.834813937425803,
//         "lng": -105.77441916594583,
//         "zoom": 19,
//         "name": "Módulo Escuinapa, Sinaloa",
//         "address": "Calle Hidalgo #44B Col. Centro C.P. 82400",
//         "schedule": "Lunes a Domingo 6:00 a 20:00 hrs",
//         "feature": "Terminal con taquilla, sala de espera pequeña, baños hombre- mujer, WiFi",
//         "phone": "01 (695) 953 0288"
//     },
//     {
//         "id": 30,
//         "lat": 22.99309273473799,
//         "lng": -105.86189407077352,
//         "zoom": 19,
//         "name": "Módulo El Rosario, Sinaloa",
//         "address": "Avenida Rotarismo s/n salida a Agua Verde, Col. Marcelo Loya, El Rosario, Sinaloa, C.P. 82808",
//         "schedule": "Lunes a Domingo 6:00 a 20:00 hrs",
//         "feature": "Terminal con taquilla, sala de espera, aire acondicionado.",
//         "phone": "01 (694) 952 0138"
//     },
//     {
//         "id": 17,
//         "lat": 29.0997954234394,
//         "lng": -110.99408704235599,
//         "zoom": 19,
//         "name": "Módulo Plaza Girasol - Hermosillo, Sonora",
//         "address": "Blvd. Solidaridad No. 56 Local 17, Col. El Sahuaro, C.P. 83178",
//         "schedule": "Lunes a Viernes 9:00 a 19:00 hrs - Sábado 9:00 a 14:00 hrs - Domingo CERRADO",
//         "feature": "Modulo de paqueteria y venta de boletos",
//         "phone": "01 (662) 216 5600"
//     },
//     {
//         "id": 10,
//         "lat": 32.482171,
//         "lng": -114.776032,
//         "zoom": 19,
//         "name": "Módulo Centro - S.L.R.C., Sonora",
//         "address": "Alvaro Obregón S/N, entre 5ta y 6ta, Col. Centro C.P. 83449",
//         "schedule": "Lunes a Sábado de 8:00 a 20:00 hrs - Domingo de 8:00 a 15:00 hrs",
//         "feature": "Terminal con taquilla, baño hombre- mujer, sala de espera, pantalla, aire acondicionado.",
//         "phone": "01 (653) 534 5832"
//     },
//     {
//         "id": 31,
//         "lat": 31.8675214039597,
//         "lng": -112.845455900268,
//         "zoom": 19,
//         "name": "Módulo Sonoyta, Sonora",
//         "address": "Blvd. Benemérito de las Américas y Blvd. Eusebio Kino No. 104, Local F, Col. Centro, C.P. 83570",
//         "schedule": "Lunes a Domingo 8:00 a 21:00 hrs",
//         "feature": "Terminal con taquilla, sala de espera, WiFi, aire acondicionado.",
//         "phone": "01 (651) 512 0868"
//     },
//     {
//         "id": 6,
//         "lat": 30.717340385498865,
//         "lng": -112.14309657065697,
//         "zoom": 19,
//         "name": "Módulo Caborca, Sonora",
//         "address": "Carretera Internacional s/n entre Calle 8 y 9, C.P. 83600",
//         "schedule": "Lunes a Sabado 9:00 a 17:00  -  Domingo Cerrado",
//         "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, pantallas, aire acondicionado.",
//         "phone": "01 (637) 372 3694"
//     },
//     {
//         "id": 24,
//         "lat": 31.296434924285222,
//         "lng": -110.93730419598934,
//         "zoom": 19,
//         "name": "Módulo Nogales, Sonora",
//         "address": "Prolongacion Ave. Alvaro Obregón # 2030, Col. Jardines Kalitea, C.P. 84160",
//         "schedule": "Lunes a Sábado 7:00 a 21:00 hrs - Domingo 8:00 a 15:00 hrs",
//         "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, cafetería, pantallas, WiFi, barra de conectores, aire acondicionado",
//         "phone": "01 (631) 313 9965"
//     },
//     {
//         "id": 33,
//         "lat": 30.782474765306265,
//         "lng": -110.85342776835533,
//         "zoom": 19,
//         "name": "Módulo Imuris, Sonora",
//         "address": "Luis Donaldo Colosio # 175 Col. Centro, CP 84120",
//         "schedule": "Lunes a Domingo 6:30 a 21:00 hrs",
//         "feature": "Terminal con taquilla, sala de espera, aire acondicionado.",
//         "phone": "(632) 688 1698"
//     },
//     {
//         "id": 22,
//         "lat": 30.627468634367318,
//         "lng": -110.9679257464388,
//         "zoom": 19,
//         "name": "Módulo Magdalena - Magdalena de Kino, Sonora",
//         "address": "Allende #103, Col. Centro, C.P.84160",
//         "schedule": "Lunes a Sabado 7:00 a 20:30 hrs - Domingo Cerrado",
//         "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, pantallas, aire acondicionado.",
//         "phone": "01 (632) 322 0613"
//     },
//     {
//         "id": 17,
//         "lat": 29.07829148290357,
//         "lng": -110.93060853673211,
//         "zoom": 19,
//         "name": "Centro Operativo Hermosillo, Sonora",
//         "address": "Blvd. Luis Encinas y Ferrocarril #393 Col. Colosio Bajo C.P 84160",
//         "schedule": "Lunes a Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs",
//         "feature": "Sucursal de paqueteria",
//         "phone": "01 (662) 289 6970"
//     },
//     {
//         "id": 1,
//         "lat": 31.312344812164834,
//         "lng": -109.55766268315524,
//         "zoom": 19,
//         "name": "Módulo Agua Prieta, Sonora",
//         "address": "Avenida 6 y Calle 25 S/N, Col. Ejidal C.P. 84200",
//         "schedule": "Lunes a Viernes 7:00 a 21:00 hrs - Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs",
//         "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, cafetería, pantalla, aire acondicionado.",
//         "phone": "01 (633) 338 3773"
//     },
//     {
//         "id": 26,
//         "lat": 30.54886,
//         "lng": -111.119146,
//         "zoom": 19,
//         "name": "Módulo Santa Ana, Sonora",
//         "address": "General Anaya y Cuahutemoc s/n, Col. Del Rio, C.P.84600",
//         "schedule": "Lunes a Sábado de 7:00 a 21:00 hrs - Domingo 8:00 a 15:00 hrs",
//         "feature": "Terminal con taquilla, sala de espera, baños hombre-mujer, pantallas, aire acondicionado.",
//         "phone": "01 (641) 324 0124"
//     },
//     {
//         "id": 2,
//         "lat": 30.987951067630643,
//         "lng": -110.28641457260724,
//         "zoom": 19,
//         "name": "Módulo Cananea, Sonora",
//         "address": "Camino Real y Blvd. s/n, Colonia Linda Vista C.P. 84620",
//         "schedule": "Lunes a Viernes 6:00 a 22:00 hrs - Sábado 6:00 a 19:00 hrs - Domingo Cerrado",
//         "feature": "Terminal con taquilla, baño hombre - mujer, sala de espera, pantallas, aire acondicionado.",
//         "phone": "01 (645) 332 2500"
//     },
//     {
//         "id": 12,
//         "lat": 27.48869805276299,
//         "lng": -109.92816548687014,
//         "zoom": 19,
//         "name": "Corporativo Obregon Sufragio - Obregón, Sonora",
//         "address": "Sufragio Efectivo #440 Sur entre Zaragoza y Nicolas Bravo, Colonia Centro C.P. 85000",
//         "schedule": "Lunes a Viernes 7:30 a 17:00 hrs Sábados y Domingo CERRADO",
//         "feature": "Corporativo Grupo TUFESA",
//         "phone": "01 (644) 410 9444"
//     },
//     {
//         "id": 35,
//         "lat": 29.113808276268546,
//         "lng": -110.97295375096243,
//         "zoom": 19,
//         "name": "Módulo Monteverde - Hermosillo, Sonora",
//         "address": "Monteverde esquina Camelia s/n Col. Luis Encinas CP 85138",
//         "schedule": "Lunes a Viernes de 9:00 a 18:00 hrs  - Sabados 9:00 a 13:00  - Domingo CERRADO",
//         "feature": "Terminal con taquilla, baño hombre - mujer, 2 salas de espera, pantallas, cafeteria, aire acondicionado.",
//         "phone": "01 (662) 289 6999"
//     },
//     {
//         "id": 11,
//         "lat": 27.481338,
//         "lng": -109.945187,
//         "zoom": 19,
//         "name": "Módulo Obregon, Sonora",
//         "address": "California #1019 entre Guadalupe Victoria y Churubusco, Colonia Sochiloa C.P. 85150",
//         "schedule": "Lunes a Sabado 7:00 a 20:0 hrs - Domingo 8:00 a 15:00 hrs",
//         "feature": "Centro de paqueteria con sala de espera y aire aocndicionado",
//         "phone": "01 (644) 416 2668"
//     },
//     {
//         "id": 3,
//         "lat": 27.952433609547338,
//         "lng": -110.81239851765099,
//         "zoom": 19,
//         "name": "Módulo Empalme, Sonora",
//         "address": "Carretera Internacional, Km 2, Sector Oriente Frente al Cuartel C.P.85330",
//         "schedule": "Lunes a Sabado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs",
//         "feature": "\"",
//         "phone": "Terminal con taquilla, sala de espera, baños hombre - mujer, aire acondicionado, pantallas.\""
//     },
//     {
//         "id": 16,
//         "lat": 27.92499614490003,
//         "lng": -110.91041745449648,
//         "zoom": 19,
//         "name": "Módulo Guaymas, Sonora",
//         "address": "Blvd. Calzada Agustín Garcia Lopez y Calle Jaiba # 927, Col. Las Delicias, C.P. 85427",
//         "schedule": "Lunes a Sabado 7:00 a 20:00 hrs -  Domingo Cerrado",
//         "feature": "Terminal con taquilla, sala de espera, baño hombre - mujer, WiFi, cafetería, pantallas, aire acondicionado.",
//         "phone": "01 (622) 222 5453"
//     },
//     {
//         "id": 4,
//         "lat": 27.080485870954696,
//         "lng": -109.44579889624245,
//         "zoom": 19,
//         "name": "Módulo Navojoa, Sonora",
//         "address": "Hidalgo s/n entre No Reelección y Pesqueira, Colonia Centro C.P. 85800",
//         "schedule": "Lunes a Sabado 7:00 a 20:00 hrs -Domingo Cerrado",
//         "feature": "Terminal con taquilla , baños hombre - mujer, sala de espera, cafetería, televisión, WiFi, aire acondicionado.",
//         "phone": "01 (642) 421 3410"
//     },
//     {
//         "id": 37,
//         "lat": 26.82971275356629,
//         "lng": -109.64162829975238,
//         "zoom": 19,
//         "name": "Módulo Huatabampo, Sonora",
//         "address": "16 de Septiembre 534, Centro Comercial, 85900 Huatabampo, Son.",
//         "schedule": "Lunes a Sábado 8:00 a 20:00 hrs - Domingo Cerrado",
//         "feature": "",
//         "phone": "01 (642) 421 3410"
//     },
//     {
//         "id": 36,
//         "lat": 29.096865521640737,
//         "lng": -110.95868775421303,
//         "zoom": 19,
//         "name": "Modulo San Benito - Hermosillo, Sonora",
//         "address": "Calle Gral. José María Yáñez #143, Modelo, 83190 Hermosillo, Son.",
//         "schedule": "Lunes a Viernes 9:00 a 18:00 - Sabado 9:00 a 15:00  - Domingo Cerrado",
//         "feature": "Modulo de paqueteria y venta de boletos",
//         "phone": "01 (662) 436 2443"
//     }
// ]



    //url del marcador
  iconMap ={ iconUrl: "assets/iconos/marcador_tufesa2.png"}

  toggleMostrar(i: number, x:number) {
    if (this.activeIndex == i && this.clicked == x) {
      this.activeIndex = -1;
      this.clicked = -1;
    } else {
      this.activeIndex = i;
      this.clicked = x;
    }
  }

  //funcion para crear el zoom de un punto especifico
  UpdateZoom(lat: number, lng: number, zoom: number) {
    if (this.lati != lat) {
      this.lati = lat;
      this.long = lng;
      this.zoomdefault = zoom;
    } else {
      this.refresh();
    }
  }

  refresh() {
    this.lati = 29.33333;
    this.long = -110.66667;
    this.zoomdefault = 5;
  }

  togglePintar(x: number) {
    if (this.visitedIndex == x) {
      this.visitedIndex = -1;
    } else {
      this.visitedIndex = x;
    }
  }

}
