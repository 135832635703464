<!-- <table role="presentation"  width="100%"  cellspacing="0" cellpadding="0" style="width: 1200px;">
        <tr>
           <td style="text-align: center;padding: 20px;background: linear-gradient(180deg, rgb(241 159 21) 0%, rgb(217 70 17) 100%);">
			<img src="https://i.gifer.com/6SSp.gif" style="position: absolute;left: calc(50% - 20%);top: 150px;width: 40%;height: 35%;z-index: 999;">
              <h1 class="slideInDown" style="font-size: 48px;margin-bottom: 10px;margin-top: 10px;color: #fff;font-family: &quot;Montserrat&quot;, sans-serif;font-optical-sizing: auto;font-weight: 800;">Envíos <span style="color:#1D1D1B;">de hasta</span> 20kg <span style="color:#1D1D1B;">a</span></h1>
             
				<img src="globos_77.png" class="swing">
                <p style="font-size: 32px; margin-bottom: 20px;font-family: &quot;Montserrat&quot;, sans-serif;font-optical-sizing: auto;font-weight: 800;color:#1D1D1B;">Obtén tu promo</p>
                <table role="presentation"  cellspacing="0" cellpadding="0">
                    <tr>
                        <td style="position:relative;">
					<img src="flechas.png" class="shake" style="position: absolute;left: calc(50% - 230px);top: -40px;z-index: 998;">
						<a href="https://wa.me/6444551657" >
                            <button class="button-82-pushable" role="button">
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">
                              Button 82
                            </span>
                          </button>
                        </a>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table> -->

    <div class="container_full">
        <div class="box_promo">
            <img class="reflector fadeIn" src="assets/img/aniversario/reflector.png">
            <img class="reflector2 fadeIn" src="assets/img/aniversario/reflector2.png">
            <h1 class="slideInDown">Envíos <span class="span_col" style="color: #1D1D1B;">de hasta</span> 20kg <span class="span_col" style="color: #1D1D1B;">a</span></h1>
            <img class="confeti" src="assets/img/aniversario/confeti.gif">
            <img class="swing" src="assets/img/aniversario/globos_77.png">
            <div class="container_opt_btn">
                <p>Obtén tu promo</p>
                <img src="assets/img/aniversario/flechas.png" class="flechas shake">
                <a href="https://wa.me/526444162668?text=¡Feliz cumple TufesaPack! Quiero mi promoción" target="_blank" >
                    <button class="button-82-pushable" role="button">
                    <span class="button-82-shadow"></span>
                    <span class="button-82-edge"></span>
                    <span class="button-82-front text">
                      Manda un whatsapp
                    </span>
                  </button>
                </a>
            </div>

            <div class="container_txt_aniv">
                <h5>Celebramos nuestro</h5>
                <div class="txt_aniv">
                    <h3>1<span class="er_span">er</span> Aniversario</h3>
                </div>
                <h4>Solo este <span>18 de abril</span> del 2024</h4>
                <h6>*Precio mas impuestos</h6>
            </div>

            <div class="imgs_animated">
                <div class="animated_left">
                    <img src="assets/img/aniversario/camion.png">
                </div>

                <div class="animated_right">
                    <img src="assets/img/aniversario/cajaMagica.png">
                    <img class="abatar" src="assets/img/aniversario/abatar.png">
                </div>
            </div>
            
        </div>

        <div class="terminos">
            <p>Promoción y Vigencia Envíos de 0 hasta 20 kilos a $77.50 antes de impuestos, válido solo el 18 de abril de 2024. Los sobres especiales están incluidos en esta promoción. La promoción no es aplicable junto con otras promociones o descuentos. La tarifa se calcula con base al peso volumétrico y se aplica según el peso y las dimensiones reales del paquete. La promoción aplica únicamente al costo del envío, excluyendo cualquier costo asociado al sobre o embalaje utilizado para el traslado del paquete, el cual se cobrará en caso de que se adquiera en sucursal. 2. Servicios y Cobertura. La promoción es válida solo para envíos que salgan dentro de la cobertura de TufesaPack® y se reciban dentro de la cobertura de TufesaPack®. Se ofrece exclusivamente el servicio ocurre a ocurre, no servicio a domicilio. Sin embargo, se puede solicitar el servicio a domicilio con un cargo adicional de $50, siempre y cuando la ciudad de destino cuente con este servicio disponible. 3. Compra y Pago. Válido únicamente para compras y envíos realizados dentro de la cobertura TufesaPack®, no para compras digitales. Se acepta el pago en efectivo y con tarjeta. La promoción es válida para envíos pagados en el momento de la transacción, no para envíos por cobrar ni para contratos prepago. 4. Políticas. Los cambios y cancelaciones están sujetos a las políticas estándar de TufesaPack®️, y nos reservamos el derecho de modificar o cancelar esta promoción en cualquier momento sin previo aviso.</p>
        </div>
    </div>


