<section id="section1">
  <div class="container_popup_guiasweb" id="popop_guiasweb_movil">
      <div id="btn_close" title="cerrar" (click)="closeBtnpopupguiasmovil($event)">x</div>

      <div class="promo-slider" id="slider_popop_guiasweb_movil">

       <div id="carouselGuiasWebMovil" class="carousel slide" data-ride="carousel">
         <ol class="carousel-indicators">
           <li data-target="#carouselGuiasWebMovil"  data-slide-to="0" class="active"></li>
           <li data-target="#carouselGuiasWebMovil"  data-slide-to="1"></li>
           <li data-target="#carouselGuiasWebMovil"  data-slide-to="2"></li>
           <li data-target="#carouselGuiasWebMovil"  data-slide-to="3"></li>
           <!-- <li data-target="#carouselGuiasWebMovil"  data-slide-to="4"></li> -->
           <!-- <li data-target="#carouselGuiasWebMovil"  data-slide-to="5"></li> -->
           <!-- <li data-target="#carouselGuiasWebMovil"  data-slide-to="5"></li> -->
           <!-- <li data-target="#carouselGuiasWebMovil"  data-slide-to="6"></li> -->
         </ol>
         <div class="carousel-inner ">
            <!-- POPUP MOVIL SLIDER -->
            <!-- <div class="carousel-item active">
             <div class="container-movilpopguiasweb" id="slider_item_guiasweb_movil">
                <div style="display: flex;">
                   <img class="img-popupguiasweb" src="assets/img/promociones/popuppromodiamadre.png" id="img_mini" style="margin-left: 10px; width: 50px; height: 52px; margin-top: 8px;">
                   <div class="container-data-popguiasweb" style="margin-left: 11px;">
                      <p class="txt-popupguiasweb" style="font-size: 12px;">¡A LA MADRE!</p>
                      <p class="txt-popupguiasweb" style="line-height: 1.5; font-size: 9px; width: 90%;">Envía tus regalos a mamá con el 10% de descuento del 8 al 10 de mayo.</p>
                   </div>
                </div>
                <div class="container-data-popguiasweb" style="align-items: center; margin-left: 9px;">
                 <button id="btn_obtener_promo_tepmxl_movil" class="btn-obtener-movil" style="font-size: 10px;" routerLink="/tupromo" [queryParams]="{ code: 'PROMOTPALAMADREMY24'}">Obtener</button>
               </div>
             </div>
           </div> -->
           <!-- FIN POPUP MOVIL SLIDER  -->
           <!-- POPUP MOVIL SLIDER -->
           <!-- <div class="carousel-item">
             <div class="container-movilpopguiasweb" id="slider_item_guiasweb_movil">
                <div style="display: flex;">
                   <img class="img-popupguiasweb" src="assets/img/promociones/popuppromonenis.png" id="img_mini" style="margin-left: 10px; width: 50px; height: 52px; margin-top: 8px;">
                   <div class="container-data-popguiasweb" style="margin-left: 11px;">
                      <p class="txt-popupguiasweb" style="font-size: 12px;">¡Emprende con TufesaPack!</p>
                      <p class="txt-popupguiasweb" style="line-height: 1.5; font-size: 10px;">Llévate 20 guías web a solo $1,599</p>
                   </div>
                </div>
                <div class="container-data-popguiasweb" style="align-items: center; margin-left: 9px;">
                 <button id="btn_obtener_promo_tepmxl_movil" class="btn-obtener-movil" style="font-size: 10px;" routerLink="/tupromo" [queryParams]="{ code: 'PROMOTPNSMY24'}">Obtener</button>
               </div>
             </div>
           </div> -->
           <!-- FIN POPUP MOVIL SLIDER  -->
              <!-- POPUP MOVIL SLIDER -->
            <!-- <div class="carousel-item ">
               <div class="container-movilpopguiasweb" id="slider_item_guiasweb_movil">
                  <div style="display: flex;">
                     <img class="img-popupguiasweb" src="assets/img/promociones/popuppromocaborca.png" id="img_mini" style="margin-left: 10px; width: 50px; height: 52px; margin-top: 8px;">
                     <div class="container-data-popguiasweb" style="margin-left: 11px;">
                        <p class="txt-popupguiasweb" style="font-size: 12px;">¡Gratis: Servicio a domicilio!</p>
                        <p class="txt-popupguiasweb" style="line-height: 1.5; font-size: 9px; width: 90%;">Aprovecha y envía los juguetes favoritos de tus hijos con servicio a domicilio incluido.</p>
                     </div>
                  </div>
                  <div class="container-data-popguiasweb" style="align-items: center; margin-left: 9px;">
                   <button id="btn_obtener_promo_tepmxl_movil" class="btn-obtener-movil" style="font-size: 10px;" routerLink="/tupromo" [queryParams]="{ code: 'PROMOTPDNA24'}">Obtener</button>
                 </div>
               </div>
             </div> -->
             <!-- FIN POPUP MOVIL SLIDER  -->
            <!-- PROMMOCION ITEM 1 MOVIL-->
            <div class="carousel-item active">
              <div class="container-movilpopguiasweb" id="slider_item_guiasweb_movil">
                 <div style="display: flex;">
                    <img class="img-popupguiasweb" src="assets/img/promociones/tfpckhora_popup.png" id="img_mini" style="margin-left: 10px; width: 55px; height: 57px; margin-top: 6px">
                    <div class="container-data-popguiasweb" style="margin-left: 20px;">
                       <p class="txt-popupguiasweb" style="font-size: 10px;">Envía y recibe el mismo día</p>
                       <p class="txt-popupguiasweb" style="font-size: 7px; line-height: 1.5; font-size: 9px; width: 90%;">Nuevo Servicio ¡TufesaPack Horas!</p>
                    </div>
                 </div>
                 <div class="container-data-popguiasweb" style="align-items: center; margin-left: 9px;">
                  <button id="btn_obtener_promo_tepmxl_movil" class="btn-obtener-movil" style="font-size: 10px;" routerLink="/tufesapack-horas">¡Conócelo!</button>
                </div>
              </div>
           </div>

           <!-- <div class="carousel-item">
             <div class="container-movilpopguiasweb" id="slider_item_guiasweb_movil">
                <div style="display: flex;">
                   <img class="img-popupguiasweb" src="assets/img/promociones/popuppromocaborca.png" id="img_mini" style="margin-left: 10px; width: 55px; height: 57px; margin-top: 6px">
                   <div class="container-data-popguiasweb" style="margin-left: 20px;">
                      <p class="txt-popupguiasweb" style="font-size: 10px;">Descuentos desde Caborca</p>
                      <p class="txt-popupguiasweb" style="font-size: 7px; line-height: 1.5; font-size: 9px; width: 90%;">Envíos de sobre a $80 y de caja a $200 saliendo desde Caborca hacia ciudades dentro de Sonora. </p>
                   </div>
                </div>
                <div class="container-data-popguiasweb" style="align-items: center; margin-left: 9px;">
                 <button id="btn_obtener_promo_tepmxl_movil" class="btn-obtener-movil" style="font-size: 10px;" routerLink="/tupromo" [queryParams]="{ code: 'PROMOTPCJ24'}">Obtener</button>
               </div>
             </div>
          </div> -->
          <!-- PROMMOCION ITEM 1 FIN -->
              <!-- PROMMOCION ITEM MOVIL-->
              <!-- <div class="carousel-item">
               <div class="container-movilpopguiasweb" id="slider_item_guiasweb_movil">
                  <div style="display: flex;">
                     <img class="img-popupguiasweb" src="assets/img/promociones/poppromonavojoa.png" id="img_mini" style="margin-left: 10px; width: 55px; height: 57px; margin-top: 6px">
                     <div class="container-data-popguiasweb" style="margin-left: 20px;">
                        <p class="txt-popupguiasweb" style="font-size: 10px;">¡Precios exclusivos en Navojoa!</p>
                        <p class="txt-popupguiasweb" style="font-size: 7px; line-height: 1.5; font-size: 9px; width: 90%;">Envíos de hasta 10 kilos hacia Tijuana o Guadalajara a solo $199. </p>
                     </div>
                  </div>
                  <div class="container-data-popguiasweb" style="align-items: center; margin-left: 9px;">
                   <button id="btn_obtener_promo_tepmxl_movil" class="btn-obtener-movil" style="font-size: 10px;" routerLink="/tupromo" [queryParams]="{ code: 'PROMOTPNVJM24'}">Obtener</button>
                 </div>
               </div>
            </div> -->
            <!-- PROMMOCION ITEM FIN -->
            <!-- PROMMOCION ITEM 2 MOVIL-->
          <div class="carousel-item">
             <div class="container-movilpopguiasweb" id="slider_item_guiasweb_movil">
                <div style="display: flex;">
                   <img class="img-popupguiasweb" src="assets/img/promociones/poppromohmo.png" id="img_mini" style="margin-left: 10px; width: 50px; height: 52px; margin-top: 8px;">
                   <div class="container-data-popguiasweb" style="margin-left: 11px;">
                      <p class="txt-popupguiasweb" style="font-size: 12px;">Hermosillo: ¡Envía y ahorra!</p>
                      <p class="txt-popupguiasweb" style="line-height: 1.5; font-size: 9px; width: 90%;">Realiza tus envíos de hasta 40 kilos con un 50 %* de descuento a Tijuana, Mexicali, Tepic y Guadalajara.</p>
                   </div>
                </div>
                <div class="container-data-popguiasweb" style="align-items: center; margin-left: 9px;">
                 <button id="btn_obtener_promo_tepmxl_movil" class="btn-obtener-movil" style="font-size: 10px;" routerLink="/tupromo" [queryParams]="{ code: 'PROMOTPHMOMZ24'}">Obtener</button>
               </div>
             </div>
           </div>
          <!-- PROMMOCION ITEM 2 MOVIL FIN -->
           <!-- POPUP MOVIL SLIDER 1 -->
           <div class="carousel-item">
              <div class="container-movilpopguiasweb"  routerLink="/guias-web" id="slider_item_guiasweb_movil">
                 <div style="display: flex;">
                    <img class="img-popupguiasweb" src="assets/iconos/info-util/icon_tipsSeg.webp" id="img_mini">
                    <div class="container-data-popguiasweb" style="margin-left: 20px;">
                       <p class="txt-popupguiasweb" style="font-size: 10px;">Comprar Guias Web</p>
                       <p class="txt-popupguiasweb" style="font-size: 9px;">Consulta Restricciones</p>
                    </div>
                 </div>
                 <div class="container-data-popguiasweb" style="align-items: center; margin-right: 10px;">
                   <label class="txt-popupguiasweb" style="font-weight: 700; font-size: 10px;">$4,089.00 MX</label>
                   <p class="txt-popupguiasweb" style="font-size: 10px;">Desde</p>
                 </div>
              </div>
           </div>
           <!-- FIN POPUP MOVIL SLIDER 1 -->

           <!-- POPUP MOVIL SLIDER 2 -->
           <div class="carousel-item">
             <div class="container-movilpopguiasweb">
               <div style="display: flex;">
                  <img class="img-popupguiasweb" style="width: 56px; margin-left: 8px;" src="assets/img/popupslider3.png" id="img_mini">
                  <div class="container-data-popguiasweb" style="margin-left: 20px;">
                     <p class="txt-popupguiasweb" style="font-size: 11px;">¿Tienes código promocional?</p>
                     <p class="txt-popupguiasweb" style="font-size: 10px;">¡CANJEALO AQUÍ!</p>
                  </div>
               </div>
               <div class="container-data-popguiasweb" style="align-items: center; margin-left: 9px;">
                 <button id="btn_canjear_promo_movil" class="btn-obtener-movil" style="font-size: 10px;"  routerLink="/herramientasDigitales/documentacion/menu">Obtener</button>
               </div>
            </div>
           </div>
           <!-- FIN POPUP MOVIL SLIDER 2 -->

           <!-- POPUP MOVIL SLIDER 3 -->
           <!-- <div class="carousel-item">
             <div class="container-movilpopguiasweb">
               <div style="display: flex;">
                  <img class="img-popupguiasweb" style="width: 59px; height: 54px; margin-left: 8px;" src="assets/img/promociones/poppromosemanasanta.png" id="img_mini">
                  <div class="container-data-popguiasweb" style="margin-left: 20px;">
                     <p class="txt-popupguiasweb" style="font-size: 11px;">Guías Web a precio especial</p>
                     <p class="txt-popupguiasweb" style="font-size: 8px; line-height: 2;">Lleva 500 guías web a $39,999 más IVA</p>
                  </div>
               </div>
               <div class="container-data-popguiasweb" style="align-items: center; margin-left: 9px;">
                 <button id="btn_obtener_promo_tepmxl_movil" class="btn-obtener-movil" style="font-size: 10px;" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPSSMZ24' }">Obtener</button>
               </div>
            </div>
           </div> -->
           <!-- FIN POPUP MOVIL SLIDER 3 -->

           <!-- POPUP MOVIL SLIDER 4 -->
           <!-- <div class="carousel-item">
             <div class="container-movilpopguiasweb">
               <div style="display: flex;">
                  <img class="img-popupguiasweb" style="width: 56px; margin-left: 8px;" src="assets/img/promociones/Popup-Carnaval.png" id="img_mini">
                  <div class="container-data-popguiasweb" style="margin-left: 20px;">
                     <p class="txt-popupguiasweb" style="font-size: 11px;">¡Mazatlán al 50 % de descuento! </p>
                     <p class="txt-popupguiasweb" style="font-size: 10px;">Del 8 de febrero al 13 de febrero.</p>
                  </div>
               </div>
               <div class="container-data-popguiasweb" style="align-items: center; margin-left: 9px;">
                 <button id="btn_obtener_promo_son_movil" class="btn-obtener-movil" style="font-size: 10px;" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPMZNF24' }">Obtener</button>
               </div>
            </div>
           </div> -->
           <!-- FIN POPUP MOVIL SLIDER 4 -->
         </div>
         <a class="carousel-control-prev" href="#carouselGuiasWebMovil"  role="button" data-slide="prev">
           <span class="carousel-control-prev-icon" aria-hidden="true"></span>
           <span class="sr-only">Previous</span>
         </a>
         <a class="carousel-control-next" href="#carouselGuiasWebMovil"  role="button" data-slide="next">
           <span class="carousel-control-next-icon" aria-hidden="true"></span>
           <span class="sr-only">Next</span>
         </a>
       </div>
     </div>
   </div>

   <!-- fin contenedor principal de banners popup mobil -->

   <!-- Banners contenedor principal banners deskop y laptop -->
  <div class="container_newslide" id="slide_guiasweb">
     <div id="btn_close" title="cerrar" style="background-color: black; color: white;" (click)="closeBtnGuiaslide()">x</div>
     <div class="promo-slider" id="slide_guiasweb_desktop">

       <div id="carouselGuiasWeb" class="carousel carousel-dark slide" data-ride="carousel">
         <ol class="carousel-indicators">
            <li data-target="#carouselGuiasWeb" data-bs-target="#carouselExampleDark" data-slide-to="0" class="active"></li>
            <li data-target="#carouselGuiasWeb" data-bs-target="#carouselExampleDark" data-slide-to="1"></li>
            <li data-target="#carouselGuiasWeb" data-bs-target="#carouselExampleDark" data-slide-to="2"></li>
            <li data-target="#carouselGuiasWeb" data-bs-target="#carouselExampleDark" data-slide-to="3"></li>
            <!-- <li data-target="#carouselGuiasWeb" data-bs-target="#carouselExampleDark" data-slide-to="4"></li> -->
            <!-- <li data-target="#carouselGuiasWeb" data-bs-target="#carouselExampleDark" data-slide-to="5"></li> -->
            <!--<li data-target="#carouselGuiasWeb" data-bs-target="#carouselExampleDark" data-slide-to="5"></li> -->

         </ol>
         <div class="carousel-inner">
             <!-- PROMOCION ITEM 2 -->
             <!-- <div class="carousel-item active">
               <div class="cont-txtguiasweb">
                 <label style="font-weight: 900; font-size: 18px; margin-top: 28px;">¡A LA MADRE!</label>
                 <img class="" src="assets/img/promociones/popuppromodiamadre.png" id="" style=" width: 150px; margin-top: 17px; margin-left: 10px; margin-bottom: 25px;">
                 <div class="section-info-guias-web" style="margin-top: 0px; padding-top: 0px;">
                       <label style="text-align: center; font-size: 12px; width: 178px; margin-bottom: 37px ">Envía tus regalos a mamá con el 10% de descuento del 8 al 10 de mayo.</label>
                 </div>
                 <button id="btn_slider_comprar_guiasweb" class="btn-comprarguiasslide"  style="margin-top: 14px; font-size: 12px;" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPALAMADREMY24'}"> ¡Obtén la promo!</button>
               </div>
             </div> -->
           <!-- PROMOCION ITEM 2 FIN -->

           <!-- PROMOCION ITEM 1 -->
           <div class="carousel-item active">
            <div class="cont-txtguiasweb">
               <label style="font-weight: 900; font-size: 16px; margin-top: 0px; text-align: center; width: 159px; line-height: 1.5;">Envía y recibe el mismo día</label>
               <img class="" src="assets/img/promociones/tfpckhora_popup.png" id="" style=" width: 150px; margin-top: 30px; margin-left: 10px; margin-bottom: 20px;">
               <div class="section-info-guias-web" style="margin-top: 0px; padding-top: 0px;">
                    <label style="text-align: center; font-size: 12px; width: 178px; margin-bottom: 0px; width: 210px; line-height: 1.8;">Nuevo Servicio ¡TufesaPack Horas!</label>
                    <!-- <label style="text-align: center; font-size: 14px; width: 178px; margin-bottom: 10px ">a solo</label>
                    <label style="text-align: center; font-size: 26px; font-weight: 700; width: 178px; margin-bottom: 3px ">$1,599</label> -->
               </div>
               <button id="btn_slider_comprar_guiasweb" class="btn-comprarguiasslide"  style="margin-top: 80px; font-size: 12px;" routerLink="/tufesapack-horas"> ¡Conócelo!</button>
            </div>
          </div>
              <!-- PROMOCION ITEM 2 -->
            <!-- <div class="carousel-item">
               <div class="cont-txtguiasweb">
                  <label style="font-weight: 900; font-size: 16px; margin-top: 0px; text-align: center; width: 159px; line-height: 1.5;">Descuentos desde Caborca</label>
                  <img class="" src="assets/img/promociones/popuppromocaborca.png" id="" style=" width: 150px; margin-top: 30px; margin-left: 10px; margin-bottom: 20px;">
                  <div class="section-info-guias-web" style="margin-top: 0px; padding-top: 0px;">
                       <label style="text-align: center; font-size: 12px; width: 178px; margin-bottom: 0px; width: 210px; line-height: 1.8;">Envíos de sobre a $80 y de caja a $200 saliendo desde Caborca hacia ciudades dentro de Sonora.</label>
                       <!-- <label style="text-align: center; font-size: 14px; width: 178px; margin-bottom: 10px ">a solo</label>
                       <label style="text-align: center; font-size: 26px; font-weight: 700; width: 178px; margin-bottom: 3px ">$1,599</label> -->
                  <!-- </div>
                  <button id="btn_slider_comprar_guiasweb" class="btn-comprarguiasslide"  style="margin-top: 37px; font-size: 12px;" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPCJ24'}">¡Adquiere la promo!</button>
               </div>
             </div>  -->
           <!-- PROMOCION ITEM 2 FIN -->

            <!-- PROMOCION NAVOJOA -->
            <!-- <div class="carousel-item">
             <div class="cont-txtguiasweb">
                <label style="font-weight: 900; font-size: 16px; text-align: center; line-height: 1.5;">¡Precios exclusivos en Navojoa!</label>
                <img class="" src="assets/img/promociones/poppromonavojoa.png" id="" style=" width: 159px; margin-top: 36px; margin-left: 10px; margin-bottom: 24px;">
                <div class="section-info-guias-web" style="margin-top: 0px; padding-top: 0px;">
                     <label style="text-align: center; font-size: 12px; width: 168px;"> Envíos de hasta 10 kilos hacia Tijuana o Guadalajara a solo <strong style="font-size: 18px;">$199</strong>. </label>
                </div>
                <button id="btn_slider_comprar_guiasweb" class="btn-comprarguiasslide"  style="margin-top: 10px;" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPNVJM24'}">Obtén la promo</button>
             </div>
           </div> -->
         <!-- PROMOCION NAVOJOA FIN -->

             <!-- PROMOCION ITEM 2 -->
            <div class="carousel-item">
               <div class="cont-txtguiasweb">
                  <label style="font-weight: 900; font-size: 16px;">Hermosillo:</label>
                  <label style="font-weight: 900; font-size: 16px;">¡Envía y ahorra! </label>
                  <!-- <label style="font-size: 18px; font-weight: 900;">60 % de descuento</label> -->
                  <img class="" src="assets/img/promociones/poppromohmo.png" id="" style=" width: 150px; margin-top: 27px; margin-left: 10px; margin-bottom: 24px;">
                  <div class="section-info-guias-web" style="margin-top: 0px; padding-top: 0px;">
                       <label style="text-align: center; font-size: 12px; width: 178px;">Realiza tus envíos de hasta 40 kilos con un 50 %* de descuento a Tijuana, Mexicali, Tepic y Guadalajara.</label>
                  </div>
                  <button id="btn_slider_comprar_guiasweb" class="btn-comprarguiasslide"  style="margin-top: 14px; font-size: 12px;" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPHMOMZ24'}">¡Adquiere el descuento!</button>
               </div>
             </div>
           <!-- PROMOCION ITEM 2 FIN -->

           <!-- POPUP SLIDER 1 -->
           <div class="carousel-item">
               <div class="cont-txtguiasweb">
                  <label style="font-weight: 900; font-size: 16px;">Adquiere tus</label>
                  <label style="font-size: 25px; font-weight: 900;">Guias web</label>
                  <img class="" src="assets/iconos/info-util/icon_tipsSeg.webp" id="" style=" width: 148px; margin-top: 20px; margin-left: 10px;">
                  <div class="section-info-guias-web">
                       <label>Desde</label>
                       <label style="font-size: 16px; font-weight: 700; margin: 6px 0px 18px 0px">$4,089.00 MXN</label>
                       <label style="font-size: 10px; margin-bottom: 0px;">Comprar guias digitales</label>
                       <label style="font-size: 10px;">Consulta restricciones</label>
                  </div>
                  <button id="btn_slider_comprar_guiasweb" class="btn-comprarguiasslide" routerLink="/guias-web">Comprar</button>
               </div>
           </div>
           <!-- FIN POPUP SLIDER 1 -->

           <!-- POPUP SLIDER 2 -->
           <div class="carousel-item">
             <div class="cont-txtguiasweb">
               <label style="font-weight: 900; font-size: 12px;">¿Tienes código promocional?</label>
               <label style="font-size: 18px; font-weight: 900;">¡CANJEALO AQUÍ!</label>
               <img class="" src="assets/img/popupslider3.png" id="" style=" width: 148px; margin-top: 28px;">
               <!-- <div class="section-info-guias-web">
                    <label style="color: white;">Desde</label>
                    <label style="color: white; font-size: 30px; font-weight: 700; margin: 6px 0px 18px 0px">$750</label>
                    <label style="color: white; font-size: 10px; margin-bottom: 0px;">Comprar guias digitales</label>
                    <label style="color: white; font-size: 10px;">Consulte restricciones</label>
               </div> -->
               <div class="section-info-guias-web" style="text-align: center; font-size: 12px; background-color: unset; margin-top: 10; padding-top: 0px; margin-bottom: 0px;">
                    <p style="margin-bottom: 0px; margin-top: 27px;">¡Obtén grandes descuentos canjeando tu código promocional aquí!</p>
               </div>
               <button id="btn_slider_conjear_codigopromo" class="btn-comprarguiasslide" routerLink="/herramientasDigitales/documentacion/menu" style="margin-top: 39px;">Canjear</button>
             </div>
           </div>

           <!-- FIN POPUP SLIDER 2 -->
           <!-- POPUP SLIDER 3 -->
           <!-- <div class="carousel-item">
             <div class="cont-txtguiasweb">
               <label style="font-weight: 900; font-size: 18px;">Precio Especial</label>
               <img class="" src="assets/img/promociones/POPUP_PROMOMXL.webp" id="" style=" width: 148px; margin-top: 28px;">
               <div class="section-info-guias-web" style="text-align: center; font-size: 12px; background-color: unset; margin-top: 10; padding-top: 0px; margin-bottom: 0px;">
                    <p style="margin-bottom: 20px; margin-top: 27px; padding: 0px 10px;">Enviando desde Mexicali hacia San Luis Río Colorado y Tijuana</p>
               </div>
               <button id="btn_obtener_promo_tepmxl_desktop" class="btn-comprarguiasslide" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOMXL' }" style="margin-top: 39px; font-size: 12px;">¡Adquiere tu descuento!</button>
             </div>
           </div> -->
           <!-- FIN POPUP SLIDER 3 -->

           <!-- POPUP SLIDER 4 -->
           <!-- <div class="carousel-item">
             <div class="cont-txtguiasweb">
               <label style="font-weight: 900; font-size: 16px; text-align: center; line-height: 1.5;">¡Mazatlán al 50 % de descuento!</label>
               <img class="" src="assets/img/promociones/Popup-Carnaval.png" id="" style=" width: 148px; margin-top: 28px;">
               <div class="section-info-guias-web" style="text-align: center; font-size: 12px; background-color: unset; margin-top: 10; padding-top: 0px; margin-bottom: 0px;">
                    <p style="margin-bottom: 20px; margin-top: 27px;"> Del 8 de febrero al 13 de febrero</p>
               </div>
               <button id="btn_obtener_promo_son_desktop" class="btn-comprarguiasslide" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPMZNF24' }" style="margin-top: 39px; font-size: 12px;">¡Consigue tu promo!</button>
             </div>
           </div> -->
           <!-- FIN POPUP SLIDER 4 -->
           <!-- <div class="carousel-item" routerLink="/sucursales" id="seleccionable">
             <img src="assets/img/promociones/banner-tp3.webp" class="d-block w-100" alt="...">
           </div>  -->
         </div>
         <a class="carousel-control-prev" href="#carouselGuiasWeb" data-bs-target="#carouselExampleDark" role="button" data-slide="prev">
           <span class="carousel-control-prev-icon" aria-hidden="true"></span>
           <span class="sr-only">Previous</span>
         </a>
         <a class="carousel-control-next" href="#carouselGuiasWeb" data-bs-target="#carouselExampleDark" role="button" data-slide="next">
           <span class="carousel-control-next-icon" aria-hidden="true"></span>
           <span class="sr-only">Next</span>
         </a>
       </div>

     </div>
     <!-- <div class="cont-txtguiasweb" style="display: flex; flex-direction: column; align-items: center;">
        <label style="font-weight: 900; font-size: 16px;">Adquiere tus</label>
        <label style="font-size: 25px; font-weight: 900;">Guias web</label>
        <img class="" src="assets/iconos/info-util/icon_tipsSeg.webp" id="" style=" width: 148px;
        margin-top: 20px;">
        <div class="section-info-guias-web">
           <label style="color: white;">Desde</label>
           <label style="color: white; font-size: 30px; font-weight: 700; margin: 6px 0px 18px 0px">$750</label>
           <label style="color: white; font-size: 10px; margin-bottom: 0px;">Comprar guias digitales</label>
           <label style="color: white; font-size: 10px;">Consulte restricciones</label>
        </div>
        <button class="btn-comprarguiasslide" routerLink="/guias-web">Comprar</button>
     </div> -->
  </div>

  <!-- Mensaje Gris modificado a naranja -->
  <!-- <div class="container_slide" id="cont_slide">
     <div id="btn_close" title="cerrar" (click)="closeBtnslide()">x</div>
     <img class="img_mini_slide" src="assets/iconos/logo_tp_mini.webp" id="img_mini_slide">
      <a routerLink="/tupromo"  [queryParams]="{ code: 'TPZ50' }">
         <div class="btn_get">Canjear</div>
     </a> -->
     <!-- <a routerLink="/herramientasDigitales/documentacion/menu">
       <div class="btn_get">Canjear</div>
    </a>
     <div class="cont-txt" id="cont_txt_slide">
         <h1 class="txt_tittle">¿Tienes un código promocional?</h1>
         <p class="txt_promo">¡ Canjealo aquí !</p>
     </div>
  </div>  -->
  <!-- Fin Mensaje Gris modificado a naranja -->

  <!-- Mensaje Gris sin modificar -->
  <!-- <div class="container_slide" id="cont_slide">
      <div id="btn_close" title="cerrar" (click)="closeBtnslide()">x</div>
      <img class="img_mini_slide" src="assets/iconos/hero/promociones.png" id="img_mini_slide">
      <a routerLink="/tupromo"  [queryParams]="{ code: 'TPZ50' }">
       <div class="btn_get">Obtener</div>
      </a>
      <div class="cont-txt" id="cont_txt_slide">
         <h1 class="txt_tittle">¡Estamos en Zapopan! </h1>
         <p class="txt_promo">Obtén tu primer envío con <br> un 50 % de descuento </p>
     </div>
   </div> -->
   <!-- Fin Mensaje Gris sin modificar -->
 <app-herramientas-menu></app-herramientas-menu>
 <a href="#section2">
     <div class="arrow">
         <span></span>
         <span></span>
         <span></span>
     </div>
 </a>
</section>
<section id="section2">
 <app-servicios></app-servicios>
 <a href="#section3">
     <div class="arrow">
         <span></span>
         <span></span>
         <span></span>
     </div>
 </a>
</section>
<section id="section3">
 <app-about-us></app-about-us>
 <a href="#section4">
     <div class="arrow">
         <span></span>
         <span></span>
         <span></span>
     </div>
 </a>
</section>
<section id="section4">
 <app-informacion-util></app-informacion-util>
 <app-punto-envio></app-punto-envio>
</section>
